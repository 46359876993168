import React, { useState, useEffect, useContext } from "react"

import { Formik, Form } from "formik"
import ListenerComponent from "components/Common/Socket/ListenerComponent"
import { store as socketStore } from "components/Common/Socket/store"
import { useToasts } from "react-toast-notifications"
import { Link, withRouter } from "react-router-dom"
import moment from "moment"

import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap"

import { connect } from "react-redux"

import * as Yup from "yup"

import components from "./components"

import { createCompetition, editCompetition } from "../../../api"
import { getEventDetails } from "../../../actions"
import * as actions from "../../../../../pages/Authentication/actions"

const competitionInfo = Yup.object().shape({
  level: Yup.string().required("Required"),
  gameType: Yup.string().required("Required"),
  drawModel: Yup.string().required("Required"),
  numberOfGroups: Yup.number().when("drawModel", draw => {
    if (draw === "groups") {
      return Yup.number().required("Required")
    }
  }),
  mainListEntries: Yup.number().required("Required"),
  elo: Yup.bool().required("Required"),
  startDate: Yup.date().required("Required"),
})

const CompetitionDetails = props => {
  let {
    eventDetails = {},
    competitionDetails,
    fetchEvents,
    settings,
    id,
    match: { params: { competitionId, tab } = {} } = {},
    history,
    getSession,
  } = props

  const { addToast } = useToasts()

  const globalState = useContext(socketStore)
  const { state: { socket: { socketClientId } = {} } = {} } = globalState

  if (competitionId && !tab) {
    history.push(`/events/${id}/competitions/${competitionId}/details`)
  }

  const listenForResponse = async ({
    success,
    message,
    data: { id: newId } = {},
  }) => {
    if (!success) {
      return addToast(message, {
        appearance: "error",
        autoDismiss: true,
      })
    }

    if (!competitionId) {
      history.push(`/events/${id}/competitions/${newId}/details`)
    }

    await fetchEvents(id)

    return addToast(message, {
      appearance: "success",
      autoDismiss: true,
    })
  }

  const haveEvents = !!competitionDetails

  useEffect(() => {
    fetchEvents(id)
  }, [fetchEvents, haveEvents, id])

  const [modal, setModal] = useState(false)

  const {
    level = "",
    gameType = "",
    mlMaxNumber = "",
    numberOfGroups,
    elo = true,
    startDate = "",
    fee = [],
    drawModel = "",
    restrictions: {
      accountType = "",
      accountActivity = "",
      rank = "",
      gender = "",
      minAge = "",
      maxAge = "",
      teamAverageLevel = false,
      forbiddenToTeams = false,
    } = {},
  } = competitionDetails || {}

  const toggle = () => {
    setModal(!modal)
  }

  const saveState = (competition, type) => {

    if (new Date() <= new Date(eventDetails?.phases?.playing?.startDate)) {
      return {
        color: 'grey',
        exists: competition?.pointsSaved && competition?.scoresCompletion,
        text: null,
        tooltip: 'Not Started Yet',
      };
    }

    const colors = {
      none: 'red',
      partial: 'orange',
      partialWithoutFinal: 'green',
      complete: 'green',
    };
  
    const tooltipText = {
      pointsSaved: {
        before: 'Not Started Yet',
        none: 'Points Not Saved',
        partial: 'Points Partially Saved',
        partialWithoutFinal: 'Points Saved without Finals',
        complete: 'All Points Saved',
      },
      scoresCompletion: {
        before: 'Not Started Yet',
        none: 'No Scores',
        partial: 'Scores Incomplete',
        partialWithoutFinal: 'Scores Complete without Finals',
        complete: 'All Scores Complete',
      },
    };
  
    return {
      color: type && colors?.[`${competition[type]}`],
      exists: competition?.pointsSaved && competition?.scoresCompletion,
      text: type && competition[type] === 'partialWithoutFinal' ? 'F' : null,
      tooltip: type && tooltipText[type]?.[`${competition[type]}`],
    };
  };

  return (
    <div>
      <ListenerComponent
        listenFunction={listenForResponse}
        topic="events-response"
      />
      {
        <Formik
          key={level}
          initialValues={{
            level,
            gameType,
            drawModel,
            mainListEntries: mlMaxNumber,
            numberOfGroups,
            elo: elo,
            startDate: !competitionId
              ? eventDetails?.startDate
              : competitionDetails?.startDate,
            accountType,
            accountActivity,
            rank,
            fee: fee.reduce((acc, { country, _id, ...details }) => {
              acc[country] = details
              return acc
            }, {}),
            gender,
            minAge,
            maxAge,
            teamAverageLevel,
            forbiddenToTeams,
            //ro prize titles
            prize1Title:
              competitionDetails?.prizes?.ro &&
              competitionDetails?.prizes?.ro[0]?.title
                ? competitionDetails?.prizes?.ro &&
                  competitionDetails?.prizes?.ro[0]?.title
                : "Locul 1",
            prize2Title:
              competitionDetails?.prizes?.ro &&
              competitionDetails?.prizes?.ro[1]?.title
                ? competitionDetails?.prizes?.ro &&
                  competitionDetails?.prizes?.ro[1]?.title
                : "Locul 2",
            prize3Title:
              competitionDetails?.prizes?.ro &&
              competitionDetails?.prizes?.ro[2]?.title
                ? competitionDetails?.prizes?.ro &&
                  competitionDetails?.prizes?.ro[2]?.title
                : "",
            prize4Title:
              competitionDetails?.prizes?.ro &&
              competitionDetails?.prizes?.ro[3]?.title
                ? competitionDetails?.prizes?.ro &&
                  competitionDetails?.prizes?.ro[3]?.title
                : "",

            prize1:
              competitionDetails?.prizes?.ro &&
              competitionDetails?.prizes?.ro[0]?.prize
                ? competitionDetails?.prizes?.ro &&
                  competitionDetails?.prizes?.ro[0]?.prize
                : "Medalie & Diploma",
            prize2:
              competitionDetails?.prizes?.ro &&
              competitionDetails?.prizes?.ro[1]?.prize
                ? competitionDetails?.prizes?.ro &&
                  competitionDetails?.prizes?.ro[1]?.prize
                : "Medalie & Diploma",
            prize3:
              competitionDetails?.prizes?.ro &&
              competitionDetails?.prizes?.ro[2]?.prize
                ? competitionDetails?.prizes?.ro &&
                  competitionDetails?.prizes?.ro[2]?.prize
                : "",
            prize4:
              competitionDetails?.prizes?.ro &&
              competitionDetails?.prizes?.ro[3]?.prize
                ? competitionDetails?.prizes?.ro &&
                  competitionDetails?.prizes?.ro[3]?.prize
                : "",

            //en prize titles
            prize1EnTitle:
              competitionDetails?.prizes?.en &&
              competitionDetails?.prizes?.en[0]?.title
                ? competitionDetails?.prizes?.en &&
                  competitionDetails?.prizes?.en[0]?.title
                : "1st Place",
            prize2EnTitle:
              competitionDetails?.prizes?.en &&
              competitionDetails?.prizes?.en[1]?.title
                ? competitionDetails?.prizes?.en &&
                  competitionDetails?.prizes?.en[1]?.title
                : "2nd Place",
            prize3EnTitle:
              competitionDetails?.prizes?.en &&
              competitionDetails?.prizes?.en[2]?.title
                ? competitionDetails?.prizes?.en &&
                  competitionDetails?.prizes?.en[2]?.title
                : "",
            prize4EnTitle:
              competitionDetails?.prizes?.en &&
              competitionDetails?.prizes?.en[3]?.title
                ? competitionDetails?.prizes?.en &&
                  competitionDetails?.prizes?.en[3]?.title
                : "",

            prize1En:
              competitionDetails?.prizes?.en &&
              competitionDetails?.prizes?.en[3]?.prize
                ? competitionDetails?.prizes?.en &&
                  competitionDetails?.prizes?.en[3]?.prize
                : "Medal & Diploma",
            prize2En:
              competitionDetails?.prizes?.en &&
              competitionDetails?.prizes?.en[3]?.prize
                ? competitionDetails?.prizes?.en &&
                  competitionDetails?.prizes?.en[3]?.prize
                : "Medal & Diploma",
            prize3En:
              competitionDetails?.prizes?.en &&
              competitionDetails?.prizes?.en[3]?.prize
                ? competitionDetails?.prizes?.en &&
                  competitionDetails?.prizes?.en[3]?.prize
                : "",
            prize4En:
              competitionDetails?.prizes?.en &&
              competitionDetails?.prizes?.en[3]?.prize
                ? competitionDetails?.prizes?.en &&
                  competitionDetails?.prizes?.en[3]?.prize
                : "",

            competitionDetailsPdf: competitionDetails
              ? competitionDetails?.competitionDetailsPdf
              : "",
          }}
          validationSchema={competitionInfo}
          onSubmit={async ({
            level,
            gameType,
            drawModel,
            mainListEntries,
            numberOfGroups,
            elo,
            startDate,
            accountType,
            accountActivity,
            rank,
            gender,
            minAge,
            maxAge,
            teamAverageLevel,
            forbiddenToTeams,
            fee,

            prize1,
            prize2,
            prize3,
            prize4,

            prize1Title,
            prize2Title,
            prize3Title,
            prize4Title,
            //en prize titles
            prize1EnTitle,
            prize2EnTitle,
            prize3EnTitle,
            prize4EnTitle,

            prize1En,
            prize2En,
            prize3En,
            prize4En,

            competitionDetailsPdf,
          }) => {
            let [mergedFeeField] = settings?.fees.filter(
              ({ category }) => category === eventDetails?.category
            )

            const defaultValues = mergedFeeField[gameType]
            const mergedFee = {
              ro: {},
              bg: {},
              bh: {},
              am: {},
              hu: {},
              cz: {},
              USDBHD: {},
              USDAMD: {},
            }

            const formatedFees = []

            Object.keys(mergedFee).forEach(country => {
              const currencyType =
                country === "ro"
                  ? "RON"
                  : country === "bg"
                  ? "BGN"
                  : country === "bh"
                  ? "BHD"
                  : country === "USDBHD"
                  ? "BHD-USD"
                  : country === "USDAMD"
                  ? "AMD-USD"
                  : country === "am"
                  ? "AMD"
                  : country === "hu"
                  ? "HUF"
                  : "CZK"
              Object.keys(defaultValues[drawModel]).forEach(feeType => {
                let mergedFees = mergedFee[country]
                const currentCountry = fee[country]

                if (currentCountry && currentCountry.hasOwnProperty(feeType)) {
                  mergedFees[feeType] = currentCountry[feeType]
                } else {
                  mergedFees[feeType] =
                    defaultValues[drawModel][feeType][currencyType]
                }
              })
            })

            Object.keys(mergedFee)?.map(fee => {
              const elem = mergedFee[fee]
              formatedFees.push({
                ...elem,
                country: fee,
                currency:
                  fee === "ro"
                    ? "RON"
                    : fee === "bg"
                    ? "BGN"
                    : fee === "bh"
                    ? "BHD"
                    : fee === "USDBHD"
                    ? "BHD-USD"
                    : fee === "USDAMD"
                    ? "AMD-USD"
                    : fee === "am"
                    ? "AMD"
                    : fee === "hu"
                    ? "HUF"
                    : "CZK",
              })
            })

            try {
              if (!competitionId) {
                await getSession()
                await createCompetition({
                  level: level,
                  gameType: gameType,
                  drawModel: drawModel,
                  mlMaxNumber: mainListEntries,
                  numberOfGroups,
                  elo: elo,
                  socketClientId,
                  startDate: startDate,
                  id,
                  fee: formatedFees,
                  restrictions: {
                    accountType,
                    accountActivity,
                    rank,
                    gender,
                    minAge,
                    maxAge,
                    teamAverageLevel: teamAverageLevel,
                    forbiddenToTeams: forbiddenToTeams,
                  },
                  prizes: {
                    ro: [
                      { prize: prize1, title: prize1Title },
                      { prize: prize2, title: prize2Title },
                      { prize: prize3, title: prize3Title },
                      { prize: prize4, title: prize4Title },
                    ],
                    en: [
                      { prize: prize1En, title: prize1EnTitle },
                      { prize: prize2En, title: prize2EnTitle },
                      { prize: prize3En, title: prize3EnTitle },
                      { prize: prize4En, title: prize4EnTitle },
                    ],
                  },
                })
              } else {
                await getSession()
                await editCompetition({
                  id,
                  competitionId: competitionId,
                  level: level,
                  gameType: gameType,
                  numberOfGroups,
                  drawModel: drawModel,
                  mlMaxNumber: mainListEntries,
                  elo: elo,
                  fee: formatedFees,
                  socketClientId,
                  startDate: startDate,
                  restrictions: {
                    accountType,
                    accountActivity,
                    rank,
                    gender,
                    minAge,
                    maxAge,
                    teamAverageLevel,
                    forbiddenToTeams: forbiddenToTeams,
                  },
                  competitionDetailsPdf: competitionDetailsPdf,
                  prizes: {
                    ro: [
                      { prize: prize1, title: prize1Title },
                      { prize: prize2, title: prize2Title },
                      { prize: prize3, title: prize3Title },
                      { prize: prize4, title: prize4Title },
                    ],
                    en: [
                      { prize: prize1En, title: prize1EnTitle },
                      { prize: prize2En, title: prize2EnTitle },
                      { prize: prize3En, title: prize3EnTitle },
                      { prize: prize4En, title: prize4EnTitle },
                    ],
                  },
                })
              }
              history.push(`/events/${eventDetails?._id}/competitions`)
            } catch (error) {
              console.log(error)
            }
          }}
        >
          {({
            errors,
            touched,
            initialValues,
            handleBlur,
            handleChange,
            setFieldValue,
            values,
          }) => (
            <Form>
              <br />
              <Row>
                <Col xs={12}>
                  {components.map(({ label, Component }) => (
                    <Card key={label}>
                      <CardBody>
                        {label === 'Competition Details' ? 
                        <CardTitle className="d-flex justify-content-between">
                          <div>{label}</div>
                          <div className="d-flex">{saveState(competitionDetails)?.exists && (
                            <>
                              <div className={`mr-1 score-status ${saveState(competitionDetails, 'scoresCompletion')?.color}`} id={`scores-competition-${competitionDetails?.competitionId}`}>{saveState(competitionDetails, 'scoresCompletion')?.text}</div>
                              <UncontrolledTooltip placement="top" target={`scores-competition-${competitionDetails?.competitionId}`}>
                                {saveState(competitionDetails, 'scoresCompletion')?.tooltip}
                              </UncontrolledTooltip>
                              <span className="font-size-14 font-weight-normal">{'Scores'}</span>
                              <div className={`ml-2 mr-1 score-status ${saveState(competitionDetails, 'pointsSaved')?.color}`} id={`points-competition-${competitionDetails?.competitionId}`}>{saveState(competitionDetails, 'pointsSaved')?.text}</div>
                              <UncontrolledTooltip placement="top" target={`points-competition-${competitionDetails?.competitionId}`}>
                                {saveState(competitionDetails, 'pointsSaved')?.tooltip}
                              </UncontrolledTooltip>
                              <span className="font-size-14 font-weight-normal">{'Points'}</span>
                            </>
                          )}</div>
                        </CardTitle>
                        : <CardTitle>{label}</CardTitle>}
                        <div>
                          <Component
                            {...{
                              id,
                              errors,
                              touched,
                              settings,
                              eventDetails,
                              values,
                              handleBlur,
                              initialValues,
                              handleChange,
                              setFieldValue,
                            }}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  ))}
                </Col>
              </Row>
              <div className="d-flex justify-content-between">
                <div>
                  <Button color="primary" className="mb-4" type="submit">
                    Submit
                  </Button>{" "}
                  <Button onClick={toggle} className="mb-4" type="button">
                    Cancel
                  </Button>
                </div>
                {competitionId && (
                  <p className="text-muted">
                    Created on{" "}
                    {moment(competitionDetails?.createdAt).format(
                      "MMMM Do YYYY"
                    )}
                    , by admin{" "}
                    {competitionDetails?.createdBy?.firstName +
                      " " +
                      competitionDetails?.createdBy?.lastName +
                      " #" +
                      competitionDetails?.createdBy?.userId}
                  </p>
                )}
              </div>
              <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Confirm</ModalHeader>
                <ModalBody>
                  All information entered will be lost if you cancel the
                  process. Are you sure?
                </ModalBody>
                <ModalFooter>
                  <Link to={`/events/${id}/competitions`}>
                    <Button color="primary">Yes</Button>
                  </Link>{" "}
                  <Button color="secondary" onClick={toggle}>
                    no
                  </Button>
                </ModalFooter>
              </Modal>
            </Form>
          )}
        </Formik>
      }
    </div>
  )
}

const mapStatetoProps = ({
  events: { eventDetails = {} } = {},
  session: { settings } = {},
}) => ({
  events: eventDetails,
  settings,
})

export default connect(mapStatetoProps, {
  fetchEvents: getEventDetails,
  getSession: actions.getSession,
})(withRouter(CompetitionDetails))
