import React, { useEffect, useContext } from "react"
import { useToasts } from "react-toast-notifications"

import Input from "components/Common/Input"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { Formik, Form } from "formik"
import DatePicker from "react-datepicker"
import {
  Container,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap"

import Select from "react-select"

import { connect } from "react-redux"

import { getEventDetails as getEventDetailsAction } from "../../../../../actions"
import { getMatches } from "../Matches/actions"
import { addPenalties, updatePenalties } from "./api"
import { store as socketStore } from "components/Common/Socket/store"
import ListenerComponent from "components/Common/Socket/ListenerComponent"
import { getPenalties } from "./actions"

const penaltiesTypes = [
  { label: "Announced Withdrawal", value: "ANNOUNCED_WITHDRAWAL" },
  { label: "Late Withdrawal", value: "LATE_WITHDRAWAL" },
  { label: "No Show", value: "NO_SHOW" },
  { label: "Administrative Penalty", value: "ADMINISTRATIVE_PENALTY" },
]

const banTypes = [
  { label: "No Ban Weeks", value: 0 },
  { label: "1 Week", value: 1 },
  { label: "2 Weeks", value: 2 },
  { label: "3 Weeks", value: 3 },
  { label: "4 Weeks", value: 4 },
  { label: "5 Weeks", value: 5 },
  { label: "6 Weeks", value: 6 },
  { label: "7 Weeks", value: 7 },
  { label: "8 Weeks", value: 8 },
  { label: "9 Weeks", value: 9 },
  { label: "10 Weeks", value: 10 },
  { label: "11 Weeks", value: 11 },
  { label: "12 Weeks", value: 12 },
  { label: "13 Weeks", value: 13 },
  { label: "14 Weeks", value: 14 },
  { label: "15 Weeks", value: 15 },
  { label: "16 Weeks", value: 16 },
  { label: "17 Weeks", value: 17 },
  { label: "18 Weeks", value: 18 },
  { label: "19 Weeks", value: 19 },
  { label: "20 Weeks", value: 20 },
  { label: "21 Weeks", value: 21 },
  { label: "22 Weeks", value: 22 },
  { label: "23 Weeks", value: 23 },
  { label: "24 Weeks", value: 24 },
  { label: "25 Weeks", value: 25 },
  { label: "26 Weeks", value: 26 },
  { label: "27 Weeks", value: 27 },
  { label: "28 Weeks", value: 28 },
  { label: "29 Weeks", value: 29 },
  { label: "30 Weeks", value: 30 },
  { label: "31 Weeks", value: 31 },
  { label: "32 Weeks", value: 32 },
  { label: "33 Weeks", value: 33 },
  { label: "34 Weeks", value: 34 },
  { label: "35 Weeks", value: 35 },
  { label: "36 Weeks", value: 36 },
  { label: "37 Weeks", value: 37 },
  { label: "38 Weeks", value: 38 },
  { label: "39 Weeks", value: 39 },
  { label: "40 Weeks", value: 40 },
  { label: "41 Weeks", value: 41 },
  { label: "42 Weeks", value: 42 },
  { label: "43 Weeks", value: 43 },
  { label: "44 Weeks", value: 44 },
  { label: "45 Weeks", value: 45 },
  { label: "46 Weeks", value: 46 },
  { label: "47 Weeks", value: 47 },
  { label: "48 Weeks", value: 48 },
  { label: "49 Weeks", value: 49 },
  { label: "50 Weeks", value: 50 },
  { label: "51 Weeks", value: 51 },
  { label: "52 Weeks", value: 52 },
]

const penaltiesSchema = Yup.object().shape({
  penaltiesType: Yup.string().required("Required"),
  startDate: Yup.date().required("Required"),
  player: Yup.object().required("Required"),
})

const AddPenalties = ({
  match: { params: { id, competitionId, penaltyId } = {} } = {},
  getEventDetails,
  eventDetails,
  fetchPenalties,
  fetchMatches,
  matches,
  penalties,
  settings,
}) => {
  const { addToast } = useToasts()

  useEffect(() => {
    getEventDetails(id)
  }, [getEventDetails, id, competitionId])
  let currentCompetition = eventDetails?.filter(x => x?._id === id)[0]

  currentCompetition = currentCompetition?.competitions?.filter(
    y => y._id === competitionId
  )[0]

  const mainListPlayers = currentCompetition?.mainList
  const currentEvent = eventDetails?.find(x => x?._id === id)
  const currentCompetitionId = currentCompetition?.competitionId

  const globalState = useContext(socketStore)
  const { state: { socket: { socketClientId } = {} } = {} } = globalState

  const listenForResponse = async ({ success, message }) => {
    if (!success) {
      return addToast("Couldn't add penalty", {
        appearance: "error",
        autoDismiss: true,
      })
    }
    return addToast("Successfully added new penalty", {
      appearance: "success",
      autoDismiss: true,
    })
  }

  useEffect(() => {
    fetchPenalties({ competitionId })
    fetchMatches({ competitionId })
  }, [fetchPenalties, competitionId, fetchMatches])

  let currentPenalty
  let currentSelectedPlayer

  if (penaltyId && penalties?.penalties?.length) {
    currentPenalty = penalties?.penalties?.filter(x => x?._id === penaltyId)
    currentSelectedPlayer = mainListPlayers?.filter(
      player => player?.userId === currentPenalty[0]?.userId
    )
  }

  //get the user from suggested penalties

  let matchesByePlayers = []
  let currentSuggestedPlayer

  const getCurrentSuggestedPlayer = () => {
    if ((penaltyId && !currentPenalty) || currentPenalty?.length === 0) {
      matches?.map(match => {
        if (match?.player?.type === "BYE") {
          matchesByePlayers.push(match?.player)
          match?.playerPartner && matchesByePlayers.push(match?.playerPartner)
        }
        if (match?.opponent?.type === "BYE") {
          matchesByePlayers.push(match?.opponent)
          match?.opponentPartner &&
            matchesByePlayers.push(match?.opponentPartner)
        }
      })
    }
    currentSuggestedPlayer = matchesByePlayers?.filter(
      user => user?.userId == penaltyId
    )[0]
  }

  getCurrentSuggestedPlayer()

  const formatMainListPlayers = arr => {
    const formatedMainListPlayers = []
    arr?.map(player => {
      formatedMainListPlayers.push(player?.user)

      if (player?.partner) {
        formatedMainListPlayers.push(player?.partner)
      }
    })

    return formatedMainListPlayers
  }

  return (
    <Container fluid>
      <ListenerComponent
        listenFunction={listenForResponse}
        topic="accounts-response"
      />
      <Card className="w-50">
        <CardBody>
          <div className="page-content">
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/events-management">Events</Link>
              </BreadcrumbItem>

              <BreadcrumbItem>
                <Link to={`/events/${id}/competitions`}>
                  Event: {eventDetails[0]?.name}{" "}
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link
                  to={`/events/${eventDetails[0]?._id}/competitions/${currentCompetition?._id}/penalties`}
                >
                  Competition: {currentCompetition?.competitionId}{" "}
                </Link>
              </BreadcrumbItem>
              {penaltyId ? (
                <BreadcrumbItem active>
                  Penalty userId:{" "}
                  {currentSuggestedPlayer
                    ? currentSuggestedPlayer?.userId
                    : currentPenalty?.length && currentPenalty[0]?.userId}
                </BreadcrumbItem>
              ) : (
                <BreadcrumbItem active>Create New Penalty</BreadcrumbItem>
              )}
            </Breadcrumb>
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="mb-1">
                {currentSuggestedPlayer
                  ? "Add Penalty"
                  : penaltyId
                  ? "Edit Penalty"
                  : "Add Penalty"}
              </h1>

              <h6 className="mb-1">
                {id
                  ? eventDetails[0]?.currentPhase?.status
                    ? eventDetails[0]?.currentPhase?.status
                    : "No Current Stage"
                  : ""}
              </h6>
            </div>

            <Formik
              key={currentPenalty && currentPenalty[0]?._id}
              initialValues={{
                penaltiesType: currentPenalty && currentPenalty[0]?.type,
                banned: currentPenalty && currentPenalty[0]?.banned,
                startDate: currentPenalty?.length
                  ? currentPenalty[0]?.startDate
                  : new Date(),
                reason: currentPenalty?.length ? currentPenalty[0]?.reason : "",
                player: penaltyId ? {} : "",
              }}
              onSubmit={values => {
                try {
                  if (!penaltyId || currentSuggestedPlayer) {
                    addPenalties({
                      socketClientId,
                      id: currentSuggestedPlayer
                        ? currentSuggestedPlayer?.userId
                        : values.player.userId,
                      penalty: {
                        banned: values.banned,
                        type: values.penaltiesType,
                        startDate: values.startDate,
                        competition: {
                          id: competitionId,
                          competitionId: currentCompetitionId,
                          gameType: currentCompetition?.gameType,
                        },
                        event: {
                          id: id,
                          eventId: currentEvent?.eventId,
                          sportType: currentEvent?.sportType,
                        },
                        reason: values.reason,
                      },
                    })
                  } else {
                    updatePenalties({
                      socketClientId,
                      penaltyId,
                      userId: currentSelectedPlayer[0]?.user?.userId
                        ? currentSelectedPlayer[0]?.user?.userId
                        : currentPenalty[0]?.userId,
                      id: values.player.userId,
                      penalty: {
                        banned: values.banned,
                        type: values.penaltiesType,
                        startDate: values.startDate,
                        competition: {
                          id: competitionId,
                          competitionId: currentCompetitionId,
                          gameType: currentCompetition?.gameType,
                        },
                        event: { id: id, eventId: currentEventId },
                        reason: values.reason,
                        user: values.player,
                      },
                    })
                  }
                } catch (error) {
                  console.log(error)
                }
              }}
              validationSchema={penaltiesSchema}
            >
              {({
                errors,
                touched,
                handleChange,
                handleBlur,
                initialValues,
                values,
                setFieldValue,
                setFieldTouched,
              }) => {
                return (
                  <Form>
                    {currentSelectedPlayer && (
                      <div className="d-flex align-items-center">
                        <h4 className="mb-0">
                          {currentPenalty[0]
                            ? currentPenalty[0]?.playerName + " - id: "
                            : currentSuggestedPlayer
                            ? currentSuggestedPlayer?.firstName +
                              " " +
                              currentSuggestedPlayer?.lastName +
                              " - id: "
                            : currentSelectedPlayer[0]?.user?.firstName +
                              " " +
                              currentSelectedPlayer[0]?.user?.lastName +
                              " - Id: "}
                        </h4>

                        {/* fix link to currentSuggestedPlayer */}
                        <Link
                          target="_blank"
                          to={`/accounts/players/${currentSelectedPlayer[0]?.user?.id}`}
                        >
                          <Badge
                            className="d-flex align-items-center ml-1"
                            color="primary"
                          >
                            <h4 className="mb-0 text-white">
                              {currentPenalty[0]
                                ? currentPenalty[0]?.userId
                                : currentSuggestedPlayer
                                ? currentSuggestedPlayer?.userId
                                : currentSelectedPlayer[0]?.user?.userId}
                            </h4>
                          </Badge>
                        </Link>
                      </div>
                    )}
                    {!penaltyId && (
                      <FormGroup>
                        <Label>Select Player*</Label>
                        <Select
                          placeholder="Player"
                          name="player"
                          options={formatMainListPlayers(mainListPlayers)?.map(
                            player => ({
                              label: `${player?.firstName + " " + player?.lastName} id: ${player?.userId} Single: ${player?.gameLevel?.singles} Double: ${player?.gameLevel?.doubles} ${player?.location?.name} Type: ${player?.membership?.plan}`,
                              value: player,
                            })
                          )}
                          defaultValue={
                            currentSelectedPlayer && {
                              label:
                                currentSelectedPlayer[0]?.user?.firstName +
                                " " +
                                currentSelectedPlayer[0]?.user?.lastName,

                              value: currentSelectedPlayer[0]?.user,
                            }
                          }
                          onChange={e => setFieldValue("player", e.value)}
                          onBlur={setFieldTouched}
                          {...{ errors, touched }}
                        />
                        {errors &&
                        errors["player"] &&
                        touched &&
                        touched["player"] ? (
                          <div className="error">{errors["player"]}</div>
                        ) : null}
                      </FormGroup>
                    )}

                    {/* penalty type */}
                    <FormGroup>
                      <Label>Select Type*</Label>
                      <Select
                        placeholder="Type"
                        name="penaltiesType"
                        options={penaltiesTypes?.map(type => ({
                          label: type.label,
                          value: type.value,
                        }))}
                        defaultValue={
                          penaltyId && {
                            value: initialValues?.penaltiesType,
                            label: penaltiesTypes?.filter(
                              x => x.value === initialValues.penaltiesType
                            )[0]?.label,
                          }
                        }
                        onChange={e => setFieldValue("penaltiesType", e.value)}
                        onBlur={setFieldTouched}
                        {...{ errors, touched }}
                      />
                      {errors &&
                      errors["penaltiesType"] &&
                      touched &&
                      touched["penaltiesType"] ? (
                        <div className="error">{errors["penaltiesType"]}</div>
                      ) : null}
                    </FormGroup>

                    <FormGroup>
                      <Input
                        placeholder="Set Reason"
                        label="Set Reason"
                        name="reason"
                        {...{
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          initialValues,
                          values,
                        }}
                      />
                    </FormGroup>

                    <FormGroup>
                      <label className="mb-1">Start date*</label>
                      <DatePicker
                        selected={
                          new Date(
                            values.startDate ||
                              initialValues.startDate ||
                              new Date()
                          )
                        }
                        onChange={value => setFieldValue("startDate", value)}
                        dateFormat="Pp"
                        className="form-control"
                        showTimeSelect
                      />
                      {errors &&
                      errors["startDate"] &&
                      touched &&
                      touched["startDate"] ? (
                        <div className="error">{errors["startDate"]}</div>
                      ) : null}
                    </FormGroup>

                    {/* weeks ban */}
                    <FormGroup>
                      <Label>Select Ban Weeks</Label>
                      <Select
                        placeholder="Ban Weeks"
                        name="banned"
                        options={banTypes?.map(type => ({
                          label: type?.label,
                          value: type?.value,
                        }))}
                        defaultValue={
                          penaltyId && {
                            value: initialValues?.banned,
                            label: banTypes?.filter(
                              x => x.value === initialValues.banned
                            )[0]?.label,
                          }
                        }
                        onChange={e => setFieldValue("banned", e.value)}
                        onBlur={setFieldTouched}
                        {...{ errors, touched }}
                      />
                      {errors &&
                      errors["banned"] &&
                      touched &&
                      touched["banned"] ? (
                        <div className="error">{errors["banned"]}</div>
                      ) : null}
                    </FormGroup>

                    <Button className="mr-2" color="danger">
                      <Link
                        className="text-white"
                        to={`/events/${id}/competitions/${competitionId}/penalties`}
                      >
                        Back
                      </Link>
                    </Button>
                    <Button color="primary">
                      {currentSuggestedPlayer
                        ? "Add"
                        : penaltyId
                        ? "Update"
                        : "Add"}
                    </Button>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </Container>
  )
}

const mapStateToProps = ({
  events: { eventDetails = [] } = {},
  penalties: { penalties = [] } = {},
  matches: { matches = [] } = {},
  session: { settings } = {},
}) => ({
  eventDetails,
  penalties,
  settings,
  matches,
})

export default connect(mapStateToProps, {
  getEventDetails: getEventDetailsAction,
  fetchPenalties: getPenalties,
  fetchMatches: getMatches,
})(AddPenalties)
