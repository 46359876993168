import React, { useState, useContext } from "react"
import { useToasts } from "react-toast-notifications"
import Slot from "../../components/Slot"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import Input from "components/Common/Input"
import { setLuckyDraw, setMainDraw, updateLuckyDraw } from "../../../../../api"
import { store as socketStore } from "components/Common/Socket/store"
import ListenerComponent from "components/Common/Socket/ListenerComponent"
import { connect } from "react-redux"
import { getEventDetails as getEventDetailsAction } from "../../../../../actions"
import { Card, CardBody } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"

const Eliminatory = ({
  luckyDraw,
  socketClientId,
  eventId,
  competitionId,
  gameType,
  allCompetitions,
  currentCompetition,
}) => {
  const [positionDropdown, setPositionDropdown] = useState(null)
  const [selectedStage, setSelectedStage] = useState(1)
  const [selectedPosition, setSelectedPosition] = useState(null)

  const qualifications = [...luckyDraw].filter(({ stage }) => stage === 0)
  const firstStage = [...luckyDraw]
    .filter(({ stage }) => stage === 1)
    .map(({ slot = {}, ...rest }) =>
      slot.user === "PENDING" || slot.user === "BYE"
        ? { slot: { user: { firstName: slot.user } }, ...rest }
        : { slot, ...rest }
    )
  const sortedDraw = [...firstStage].sort(
    (curr, next) => curr.position - next.position
  )

  const getCurrentPlayerEntries = playerId => {
    let numberOfRegistrations = 0
    let allEntries = []
    allCompetitions?.map(competition => {
      allEntries = [
        ...allEntries,
        ...competition?.mainList,
        ...competition?.waitingList,
        ...competition?.preRegistrationList,
      ]
    })

    allEntries?.map(entry => {
      if (playerId === entry?.user?.userId || playerId === entry?.partnerId) {
        numberOfRegistrations = numberOfRegistrations + 1
      }
    })

    return numberOfRegistrations
  }

  const stages = [qualifications, sortedDraw]
  return (
    <div
      className={`LuckyDraw mt-2 ${
        qualifications.length &&
        qualifications[0]?.slot?.partner &&
        "duoLuckyDraw"
      } ${qualifications.length && "LuckyDraw__qualifications"}`}
    >
      <div>
        {sortedDraw.map(
          (
            {
              slot: { user, partner, competitionRank } = {},
              position,
              seeded,
              stage,
            },
            index
          ) => (
            <div
              className={`slot__wrapper ${
                qualifications.filter(
                  ({ nextStagePosition }) => nextStagePosition === position
                ).length && "slot__wrapper--qualifications"
              } ${(index + 1) % 2 === 0 && "slot__wrapper--spacing"}`}
            >
              <div className="qualifications">
                {qualifications
                  .filter(
                    ({ nextStagePosition }) => nextStagePosition === position
                  )
                  .map(
                    ({
                      slot: { user, partner, competitionRank } = {},
                      stage,
                      position,
                      ...props
                    }) => (
                      <Slot
                        competitionDetails={currentCompetition}
                        partnerId={partner?.userId}
                        {...{
                          user,
                          partner,
                          gameType,
                          positionClick: () =>
                            setPositionDropdown({
                              lastPosition: position,
                              lastStage: stage,
                            }),
                          position,
                          ...props,
                        }}
                        playerEntries={getCurrentPlayerEntries(user?.userId)}
                        partnerEntries={getCurrentPlayerEntries(
                          partner?.userId
                        )}
                      />
                    )
                  )}
              </div>

              <Slot
                competitionDetails={currentCompetition}
                partnerId={partner?.userId}
                position={position}
                positionClick={() =>
                  setPositionDropdown({
                    lastPosition: position,
                    lastStage: stage,
                  })
                }
                user={user}
                gameType={gameType}
                partner={partner}
                competitionPosition={competitionRank}
                seeded={seeded}
                playerEntries={getCurrentPlayerEntries(user?.userId)}
                partnerEntries={getCurrentPlayerEntries(partner?.userId)}
              />
            </div>
          )
        )}
      </div>
      <Modal
        isOpen={positionDropdown}
        toggle={() => {
          setSelectedStage(1)
          setPositionDropdown(null)
        }}
      >
        <ModalHeader>Change player position</ModalHeader>
        <ModalBody>
          <Input
            onChange={e => setSelectedStage(e.target.value)}
            placeholder="Stage"
            label="Stage*"
            type="select"
            name="stage"
          >
            <option value="0">Qualifications</option>
            <option value="1" selected>
              First Stage
            </option>
          </Input>
          <br />
          <Input
            key={selectedStage}
            onChange={e => setSelectedPosition(e.target.value)}
            placeholder="New position"
            label="New position*"
            type="select"
            name="position"
          >
            <option value="" selected disabled>
              Select Position
            </option>
            {stages[selectedStage]
              ?.sort((a, b) => a?.position - b?.position)
              .map(({ position }) => (
                <option value={position}>Position {position}</option>
              ))}
          </Input>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!selectedPosition}
            onClick={async () => {
              await updateLuckyDraw({
                socketClientId,
                eventId,
                competitionId,
                lastStage: positionDropdown.lastStage,
                lastPosition: positionDropdown.lastPosition,
                position: Number(selectedPosition),
                stage: Number(selectedStage),
              })
              setPositionDropdown(null)
              setSelectedStage(1)
            }}
            color="primary"
          >
            Change
          </Button>
          <Button
            onClick={() => {
              setSelectedStage(1)
              setPositionDropdown(null)
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const Groups = ({
  luckyDraw,
  numberOfGroups,
  socketClientId,
  eventId,
  gameType,
  competitionId,
  allCompetitions,
  currentCompetition,
}) => {
  const [positionDropdown, setPositionDropdown] = useState(null)
  const [selectedPosition, setSelectedPosition] = useState(null)
  const [selectedGroup, setSelectedGroup] = useState(null)
  const groups = new Array(numberOfGroups).fill(null).map((el, index) => ({
    group: index + 1,
    groupLetter: (index + 10).toString(36).toUpperCase(),
    players: luckyDraw
      .filter(({ group }) => group === index + 1)
      .map(({ slot, ...rest }) =>
        slot.user === "BYE"
          ? { slot: { user: { firstName: slot.user } }, ...rest }
          : { slot, ...rest }
      )
      .sort((curr, next) => curr.position - next.position),
  }))
  const positionsPerGroup = groups[0].players.length

  const getCurrentPlayerEntries = playerId => {
    let numberOfRegistrations = 0
    let allEntries = []
    allCompetitions?.map(competition => {
      allEntries = [
        ...allEntries,
        ...competition?.mainList,
        ...competition?.waitingList,
        ...competition?.preRegistrationList,
      ]
    })

    allEntries?.map(entry => {
      if (playerId === entry?.user?.userId || playerId === entry?.partnerId) {
        numberOfRegistrations = numberOfRegistrations + 1
      }
    })

    return numberOfRegistrations
  }

  return (
    <div className="LuckyDraw">
      {groups.map(({ group, groupLetter, players }) => (
        <div>
          <br />

          <h3 className="mt-2 mb-2">Group {groupLetter}</h3>
          {players.map(
            ({ position, slot: { user, partner, competitionRank } = {} }) => (
              <Slot
                competitionDetails={currentCompetition}
                partnerId={partner?.userId}
                position={position}
                positionClick={() =>
                  setPositionDropdown({
                    lastPosition: position,
                    lastGroup: group,
                  })
                }
                gameType={gameType}
                user={user}
                partner={partner}
                competitionPosition={competitionRank}
                playerEntries={getCurrentPlayerEntries(user?.userId)}
                partnerEntries={getCurrentPlayerEntries(partner?.userId)}
                seeded={competitionRank <= currentCompetition?.numberOfGroups}
              />
            )
          )}
        </div>
      ))}
      <Modal
        isOpen={positionDropdown}
        toggle={() => {
          setPositionDropdown(null)
          setSelectedGroup(null)
        }}
      >
        <ModalHeader>Change player position</ModalHeader>
        <ModalBody>
          <Input
            placeholder="Group"
            label="Group*"
            type="select"
            name="group"
            onChange={e => setSelectedGroup(e.target.value)}
          >
            <option value="" selected disabled>
              Select Group
            </option>
            {new Array(numberOfGroups).fill(0).map((el, index) => (
              <option value={index + 1}>Group {index + 1}</option>
            ))}
          </Input>
          <br />
          <Input
            placeholder="New position"
            label="New position*"
            type="select"
            name="position"
            onChange={e => setSelectedPosition(e.target.value)}
          >
            <option value="" selected disabled>
              Select Position
            </option>
            {new Array(positionsPerGroup).fill(0).map((el, index) => (
              <option value={index + 1}>Position {index + 1}</option>
            ))}
          </Input>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!selectedPosition || !selectedGroup}
            onClick={async () => {
              await updateLuckyDraw({
                socketClientId,
                eventId,
                competitionId,
                lastGroup: positionDropdown.lastGroup,
                lastPosition: positionDropdown.lastPosition,
                position: Number(selectedPosition),
                group: Number(selectedGroup),
              })
              setPositionDropdown(null)
            }}
            color="primary"
          >
            Change
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const Draw = ({
  competitionDetails: {
    luckyDraw = [],
    drawModel,
    numberOfGroups,
    gameType,
  } = {},
  getEventDetails,
  eventDetails,
  match,
  fetchMatches,
  matches,
}) => {
  const { addToast } = useToasts()
  const globalState = useContext(socketStore)
  const { state: { socket: { socketClientId } = {} } = {} } = globalState
  const [modal, setModal] = useState(null)
  const { id: eventId, competitionId } = match.params
  const [selectedPhase, setSelectedPhase] = useState("")
  const [phaseComfirmationModal, setPhaseComfirmationModal] = useState(false)

  const listenForResponse = async ({ success, message }) => {
    if (!success) {
      return addToast(message, {
        appearance: "error",
        autoDismiss: true,
      })
    }
    await getEventDetails(eventId)
    return addToast(message, {
      appearance: "success",
      autoDismiss: true,
    })
  }

  let currentCompetition = eventDetails[0]?.competitions?.filter(
    competition => competition?._id === competitionId
  )[0]

  return (
    <div>
      <div className="flex mt-4 justify-content-start">
        {!luckyDraw.length && <NotGeneratedDraw />}
        {luckyDraw.length ? (
          <div className="mr-5">
            <h3>{`Competition (${
              drawModel[0]?.toUpperCase() + drawModel?.slice(1)
            })`}</h3>
            <ListenerComponent
              listenFunction={listenForResponse}
              topic={["events-response", "matches-response"]}
            />
            <div>
              {drawModel === "groups" ? (
                <Groups
                  socketClientId={socketClientId}
                  currentCompetition={currentCompetition}
                  eventId={eventId}
                  competitionId={competitionId}
                  gameType={gameType}
                  luckyDraw={luckyDraw}
                  numberOfGroups={numberOfGroups}
                  allCompetitions={eventDetails[0]?.competitions}
                />
              ) : (
                <Eliminatory
                  currentCompetition={currentCompetition}
                  socketClientId={socketClientId}
                  eventId={eventId}
                  competitionId={competitionId}
                  gameType={gameType}
                  luckyDraw={luckyDraw}
                  allCompetitions={eventDetails[0]?.competitions}
                />
              )}
              <div className="Luckydraw__actions">
                <Button
                  color="success"
                  className="pl-1 pr-1"
                  onClick={async () => {
                    setModal("redraw")
                  }}
                  className="mb-4 mt-3"
                >
                  Redraw
                </Button>
                <Button
                  onClick={async () => {
                    if (
                      currentCompetition?.phases?.length === 0 &&
                      currentCompetition?.drawModel === "groups"
                    ) {
                      setPhaseComfirmationModal(!phaseComfirmationModal)
                    } else {
                      await setMainDraw({
                        competitionId,
                        eventId,
                        socketClientId,
                      })
                    }
                  }}
                  className="pl-2 pr-2 mb-4 mt-3 ml-2"
                  color="success"
                >
                  Generate Main Draw
                </Button>
              </div>
            </div>
            {phaseComfirmationModal && (
              <SweetAlert
                title={"No Phase Detected"}
                warning
                showCancel
                confirmBtnText="Yes"
                confirmBtnBsStyle="primary"
                title="No Phase Detected"
                focusCancelBtn
                onConfirm={async () => {
                  await setMainDraw({
                    competitionId,
                    eventId,
                    socketClientId,
                  })
                  setPhaseComfirmationModal(false)
                }}
                onCancel={() => setPhaseComfirmationModal(false)}
              >
                {() => (
                  <p>
                    There is no phase set for this{" "}
                    {currentCompetition?.drawModel?.replace(
                      /(^\w{1})|(\s{1}\w{1})/g,
                      match => match?.toUpperCase()
                    )}{" "}
                    competition, are you sure you want to generate the Main
                    Draw?
                  </p>
                )}
              </SweetAlert>
            )}
          </div>
        ) : (
          ""
        )}
        {currentCompetition?.phases?.map((phase, idx) => (
          <>
            {phase?.luckyDraw?.length ? (
              <div className="phaseCol mr-5" key={idx}>
                {currentCompetition?.drawModel === "groups" &&
                currentCompetition?.phases[0]?.drawModel === "eliminatory" ? (
                  ""
                ) : (
                  <h3>
                    Phase{" "}
                    {`(${
                      phase.drawModel[0]?.toUpperCase() +
                      phase.drawModel?.slice(1)
                    })`}
                  </h3>
                )}
                {phase?.drawModel === "groups" ? (
                  <Groups
                    currentCompetition={currentCompetition}
                    socketClientId={socketClientId}
                    eventId={eventId}
                    competitionId={competitionId}
                    gameType={phase.gameType}
                    luckyDraw={phase.luckyDraw}
                    numberOfGroups={phase.numberOfGroups}
                    allCompetitions={eventDetails[0]?.competitions}
                  />
                ) : (
                  <>
                    {currentCompetition?.drawModel === "groups" ? (
                      ""
                    ) : (
                      <Eliminatory
                        currentCompetition={currentCompetition}
                        socketClientId={socketClientId}
                        eventId={eventId}
                        competitionId={competitionId}
                        gameType={phase.gameType}
                        luckyDraw={phase.luckyDraw}
                        allCompetitions={eventDetails[0]?.competitions}
                      />
                    )}
                  </>
                )}
                {currentCompetition?.drawModel === "groups" &&
                currentCompetition?.phases[0]?.drawModel === "eliminatory" ? (
                  ""
                ) : (
                  <div className="Luckydraw__actions">
                    <Button
                      color="success"
                      className="pl-1 pr-1"
                      onClick={async () => {
                        setModal("redraw")
                        setSelectedPhase(phase?._id)
                      }}
                      className="mb-4 mt-3"
                    >
                      Redraw
                    </Button>
                    <Button
                      onClick={async () => {
                        await setMainDraw({
                          competitionId,
                          eventId,
                          socketClientId,
                          phaseId: phase?._id,
                        })
                      }}
                      className="pl-2 pr-2 mb-4 mt-3 ml-2"
                      color="success"
                    >
                      Generate Main Draw
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
          </>
        ))}
        <Modal isOpen={modal === "redraw"} toggle={() => setModal(null)}>
          <ModalHeader>Re-draw positions</ModalHeader>
          <ModalBody>
            <p>Are you sure you want to redraw entries positions?</p>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={async () => {
                setModal(null)
              }}
              color="secondary"
            >
              No
            </Button>
            <Button
              onClick={async () => {
                await setLuckyDraw({
                  competitionId,
                  eventId,
                  socketClientId,
                  phaseId: selectedPhase,
                })
                setModal(null)
              }}
              color="primary"
            >
              yes
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  )
}

const NotGeneratedDraw = () => {
  return (
    <Card className="w-100">
      <CardBody>
        <p className="mb-0">There are no main draw yet</p>
      </CardBody>
    </Card>
  )
}

const mapStateToProps = ({ events: { eventDetails = [] } = {} }) => ({
  eventDetails,
})

export default connect(mapStateToProps, {
  getEventDetails: getEventDetailsAction,
})(Draw)
