import { get, patch, post } from "helpers/api_helper"

export const getLogs = async (compId, filters, activePage) => {
  let response
  const formattedFilters = Object.keys(filters).map(filterKey => {
    switch (filterKey) {
      case "subscribeSL":
        return filters[filterKey] ? "subscribeSl=true" : ""
      case "subscribeML":
        return filters[filterKey] ? "subscribeMl=true" : ""
      case "subscribeWL":
        return filters[filterKey] ? "subscribeWl=true" : ""
      case "unsubscribeML":
        return filters[filterKey] ? "unsubscribeMl=true" : ""
      case "unsubscribeWL":
        return filters[filterKey] ? "unsubscribeWl=true" : ""
      case "unsubscribeSL":
        return filters[filterKey] ? "unsubscribeSl=true" : ""
      case "promote":
        return filters[filterKey] ? "promote=true" : ""
      case "reserve":
        return filters[filterKey] ? "reserve=true" : ""
      default:
        break
    }
  })
  try {
    const url = `/logs/entries`
    const params = new URLSearchParams(
      `?limit=40&page=${
        activePage + 1
      }&competitionId=${compId}&${formattedFilters.join("&")}`
    )
    response = await get(url, { params })
  } catch (error) {
    console.log(error)
  }
  return response
}

export const getMatches = async competitionId => {
  let response
  try {
    response = await get(`/matches/${competitionId}`)
  } catch (error) {
    console.log(error)
  }
  return response
}

export const getMainDraw = async (competitionId, mlNumber) => {
  let response
  try {
    response = await get(
      `/matches/${competitionId}/main-draw?mlNumber=${mlNumber}`
    )
  } catch (error) {
    console.log(error)
  }
  return response
}

export const updateMatches = async (
  matchId,
  compDetails,
  matchDetails,
  type,
  socketClientId
) => {
  let response

  const { phases, drawModel } = compDetails

  try {
    response = await patch(
      `/admin/matches/${matchId}${type ? `/${type}` : "/none"}${
        phases?.length === 0 && drawModel === "groups" ? "/true" : ""
      }`,
      { socketClientId, ...matchDetails, competitionId: compDetails._id }
    )
  } catch (error) {
    console.log(error)
  }
  return response
}

export const createMatch = async (
  playerEntry,
  opponentEntry,
  event,
  competition,
  index,
  phaseLength,
  socketClientId
) => {
  const { gameType, drawModel, level, _id, phases } = competition

  const player = playerEntry?.user
  const playerPartner = playerEntry?.partner

  const opponent = opponentEntry?.user
  const opponentPartner = opponentEntry?.partner

  let response

  const phase = phases?.length > 0 ? phases[0] : null

  const payload = {
    matchType: "competition",
    competition: {
      gameType: phase?.gameType,
      position: index + 1, // ?
      drawType: phase?.drawModel,
      type: event?.category,
      stage: 1,
      level: level,
      phaseId: phase?._id,
      id: _id,
      eventId: event?._id,
      eventName: event?.name,
      finalStage: Math.log2(phase?.mlNumber),
      winner: null,
    },
    player: {
      ...player,
      seeded: false,
      score: [],
      competitionPoints: 0,
    },
    ...(playerPartner?.userId && {
      playerPartner: {
        ...playerPartner,
        score: [],
        competitionPoints: 0,
      },
    }),
    opponent: {
      ...opponent,
      score: [],
      competitionPoints: 0,
      seeded: false,
    },
    ...(opponentPartner?.userId && {
      opponentPartner: {
        ...opponentPartner,
        score: [],
        competitionPoints: 0,
      },
    }),
  }

  try {
    response = await post(`/matches`, { ...payload, socketClientId })
  } catch (error) {
    console.log(error)
  }

  return response
}
