import React, { useState, useContext } from "react"
import { Link } from "react-router-dom"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
  UncontrolledTooltip,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap"
import moment from "moment"
import Dropzone from "react-dropzone"
import { store as socketStore } from "components/Common/Socket/store"
import { useToasts } from "react-toast-notifications"
import ListenerComponent from "components/Common/Socket/ListenerComponent"
import { post, patch } from "helpers/api_helper"

import { deleteCompetition, enableCompetition } from "../../../api"

import { connect } from "react-redux"
import { getEventDetails } from "../../../actions"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"

import downloadIcon from "../../../../../assets/images/download_pdf.svg"
import uploadIcon from "../../../../../assets/images/upload_pdf.svg"
import announcementIcon from "../../../../../assets/images/addAnnouncement.svg"

const NoCompetitions = () => {
  return <p className="no-events mb-0">There are no competitions yet</p>
}

const Competitions = ({ id, activeEvent, fetchEvents }) => {
  const [modal, setModal] = useState(false)
  const [competitionId, setCompetitionId] = useState("")
  const [modalType, setModalType] = useState("")
  const [type, setType] = useState("")

  const { addToast } = useToasts()

  const globalState = useContext(socketStore)
  const { state: { socket: { socketClientId } = {} } = {} } = globalState

  const listenForResponse = async ({ success, message }) => {
    if (!success) {
      return addToast(message, {
        appearance: "error",
        autoDismiss: true,
      })
    }

    await fetchEvents(id)

    return addToast(message, {
      appearance: "success",
      autoDismiss: true,
    })
  }

  const toggle = compId => {
    setModal(!modal)
    setCompetitionId(compId)
  }

  const { competitions = [] } = activeEvent || {}

  const uploadPdf = async (files, competitionId) => {
    const formData = new FormData()
    ;[...files].forEach(file => formData.append("file", file))
    const { data: { filename } = {} } = await post(`/assets/events/${id}`, formData)
    await patch(`events/${id}/competitions/${competitionId}`, { competitionDetailsPdf: filename, socketClientId });
    competitions.find(comp => comp._id === competitionId).competitionDetailsPdf = filename;
  }

  const saveState = (competition, type) => {

    if (new Date() <= new Date(activeEvent?.phases?.playing?.startDate)) {
      return {
        color: 'grey',
        exists: competition?.pointsSaved && competition?.scoresCompletion,
        text: null,
        tooltip: 'Not Started Yet',
      };
    }

    const colors = {
      none: 'red',
      partial: 'orange',
      partialWithoutFinal: 'green',
      complete: 'green',
    };
  
    const tooltipText = {
      pointsSaved: {
        before: 'Not Started Yet',
        none: 'Points Not Saved',
        partial: 'Points Partially Saved',
        partialWithoutFinal: 'Points Saved without Finals',
        complete: 'All Points Saved',
      },
      scoresCompletion: {
        before: 'Not Started Yet',
        none: 'No Scores',
        partial: 'Scores Incomplete',
        partialWithoutFinal: 'Scores Complete without Finals',
        complete: 'All Scores Complete',
      },
    };
  
    return {
      color: type && colors?.[`${competition[type]}`],
      exists: competition?.pointsSaved && competition?.scoresCompletion,
      text: type && competition[type] === 'partialWithoutFinal' ? 'F' : null,
      tooltip: type && tooltipText[type]?.[`${competition[type]}`],
    };
  };

  const listColumns = () => [
    {
      dataField: "id",
      text: "ID",
      formatter: (x, y) => (
        <div>
          <Link to={location => `${location.pathname}/${y?._id}/entries`}>
            <Badge color="primary" className="p-1">
              <p className="mb-0 p-1">{y?.competitionId}</p>
            </Badge>
          </Link>
        </div>
      ),
    },
    {
      dataField: "level",
      text: "Level",
      formatter: (x, y) => (
        <div className="d-flex justify-content-center">
          <p className="mb-0">{y?.level}</p>
        </div>
      ),
    },
    {
      dataField: "gameType",
      text: "Game Type",
      formatter: (x, y) => <p className="mb-0">{y?.gameType}</p>,
    },
    {
      dataField: "draw",
      text: "Draw",
      formatter: (x, y) => <p className="mb-0">{y?.drawModel}</p>,
    },
    {
      dataField: "restrictions",
      text: "Restrictions",
      formatter: (x, y) => (
        <div className="d-flex align-items-end">
          {/* Age */}
          <div className="ml-1 mr-1">
            {y?.restrictions?.minAge && y?.restrictions?.maxAge
              ? "Age: " +
                y?.restrictions?.minAge +
                " - " +
                y?.restrictions?.maxAge
              : y?.restrictions?.minAge
              ? "Age: " + y?.restrictions?.minAge + "+"
              : y?.restrictions?.maxAge &&
                "Age: " + y?.restrictions?.maxAge + " MAX"}
          </div>
          {/* Max Rank */}
          {y?.restrictions?.rank > 0 && (
            <p className="mb-0">Max Rank: {y?.restrictions.rank}</p>
          )}
          {/* Gender */}
          {y?.restrictions?.gender !== "" && (
            <div className="ml-1 mr-1">
              {y?.restrictions?.gender === "male" ? (
                <Badge className="p-1" color="info">
                  M
                </Badge>
              ) : y?.restrictions?.gender === "female" ? (
                <Badge className="p-1" color="danger">
                  W
                </Badge>
              ) : (
                y?.restrictions?.gender === "mixt" && (
                  <Badge className="p-1" color="warning">
                    mixt
                  </Badge>
                )
              )}
            </div>
          )}

          {/* Team avg level & Forbidden to Teams */}
          {y?.restrictions?.teamAverageLevel && (
            <div className="ml-1 mr-1">
              <Badge className="p-1" color="success">
                Team Average Level
              </Badge>
            </div>
          )}
          {y?.restrictions?.forbiddenToTeams && (
            <div className="ml-1 mr-1">
              <Badge className="p-1" color="success">
                Forbidden to Teams
              </Badge>
            </div>
          )}
          {/* account type & account activity */}
          {y?.restrictions?.accountType === "summerAndPremium" && (
            <Badge className="p-1" color="warning">
              Premium
            </Badge>
          )}
          {y?.restrictions?.accountActivity
            && y?.restrictions?.accountActivity !== ""
            && y?.restrictions?.accountActivity !== "any"
            && (
            <Badge className="p-1" color="warning">
              Min. activity: {y?.restrictions?.accountActivity.split("-")?.[0]} ({y?.restrictions?.accountActivity.split("-")?.[1] ? 'last 52 weeks' : 'all time'})
            </Badge>
          )}
        </div>
      ),
    },
    {
      dataField: "ml/size",
      text: "ML/Size",
      formatter: (x, y) => (
        <p className="mb-0">{y?.mainList.length + "/" + y?.mlMaxNumber}</p>
      ),
    },
    {
      dataField: "pdf",
      text: "PDF",
      formatter: (x, y) => (
        <div>
          {y.competitionDetailsPdf ? <a target='_blank' style={{color: '#495057'}} href={`${process.env.REACT_APP_API_URL}/assets/events/${id}/${y?.competitionDetailsPdf}`}><img src={downloadIcon} alt="" height="20" /></a>
          : 
            <>
              <Dropzone accept='application/pdf' maxFiles={1} onDrop={acceptedFiles => { uploadPdf(acceptedFiles, y?._id) }}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <img src={uploadIcon} alt="" height="20" id={`pdf-competition-${y?.competitionId}`} />
                  </div>
                )}
              </Dropzone>
              <UncontrolledTooltip placement="top" target={`pdf-competition-${y?.competitionId}`}>
                Upload Main Draw PDF
              </UncontrolledTooltip>
            </>
          }
        </div>
      ),
    },
    {
      dataField: "wl",
      text: "WL",
      formatter: (x, y) => (
        <div className="d-flex justify-content-center">
          <Badge color="warning" className="p-1">
            <p className="mb-0">{y?.waitingList.length}</p>
          </Badge>
        </div>
      ),
    },
    {
      dataField: "startDate",
      text: "Start Date",
      formatter: (x, y) => (
        <p className="text-dark mb-0">
          <Link to={location => `${location.pathname}/${y?._id}/entries`}>
            <p className="mb-0 text-dark">
              {moment(y?.startDate).format("MMMM Do YYYY, h:mm a")}
            </p>
          </Link>
        </p>
      ),
    },
    {
      dataField: "scoresPoints",
      text: <p className="mb-0 text-center">Scores / Points</p>,
      formatter: (cellContent, competition) => (
        <div className="d-flex justify-content-center">
          {!!saveState(competition)?.exists ? (
            <>
              <div className={`score-status ${saveState(competition, 'scoresCompletion')?.color}`} id={`scores-competition-${competition?.competitionId}`}>{saveState(competition, 'scoresCompletion')?.text}</div>
              <UncontrolledTooltip placement="top" target={`scores-competition-${competition?.competitionId}`}>
                {saveState(competition, 'scoresCompletion')?.tooltip}
              </UncontrolledTooltip>
              <div className={`score-status ${saveState(competition, 'pointsSaved')?.color}`} id={`points-competition-${competition?.competitionId}`}>{saveState(competition, 'pointsSaved')?.text}</div>
              <UncontrolledTooltip placement="top" target={`points-competition-${competition?.competitionId}`}>
                {saveState(competition, 'pointsSaved')?.tooltip}
              </UncontrolledTooltip>
            </>
          ) : 'N/A'}
        </div>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (x, y) => (
        <div>
          {y?.status === "ACCEPTED" || y?.status === "CREATED"
            ? "ACTIVE"
            : y?.status === "MAINTENANCE"
            ? "MAINTENANCE"
            : "INACTIVE"}
        </div>
      ),
    },
    {
      dataField: "actions",
      text: "Actions",
      formatter: (x, y) => (
        <div className="actions">
          <a
            href="javascript:;"
            onClick={() => {
              setModalType("deleteModal")
              toggle(y?._id)
            }}
            className="button--disable"
          >
            <i className="bx bx-trash-alt"></i>
          </a>{" "}
          {/* disable/enable logic*/}
          <a
            href="javascript:;"
            onClick={async () => {
              setModalType("statusModal")

              let type

              y?.status === "ACCEPTED" || y?.status === "CREATED"
                ? (type = "disable")
                : (type = "enable")

              setType(type)
              toggle(y?._id)
            }}
            className={
              status === "COMPETITION_DISABLED"
                ? "button--enable"
                : "button--disable"
            }
          >
            <i className="ml-2 mr-2 bx bx-show-alt"></i>
          </a>{" "}
          <Link to={location => `${location.pathname}/${y?._id}/entries`}>
            <i className="bx bx-edit-alt"></i>
          </Link>
        </div>
      ),
    },
    {
      dataField: "addAnnouncement",
      text: "",
      formatter: (x, y) => (
        <div>
          <Link to={{
            pathname: `/add-announcement/${id}`,
            state: { competitionId: y?.competitionId },
          }}>
            <img src={announcementIcon} alt="" height="16" id={`add-announcement-${y?.competitionId}`} />
          </Link>
          <UncontrolledTooltip placement="top" target={`add-announcement-${y?.competitionId}`}>
            Add Announcement
          </UncontrolledTooltip>
        </div>
      ),
    },
  ]

  const rowClasses = (row, rowIndex) => {
    if (row?.status === "DISABLED") {
      return "disabledRow"
    }
  }

  return (
    <div className="mt-4">
      <ListenerComponent
        listenFunction={listenForResponse}
        topic="events-response"
      />
      <div className="events">
        <ToolkitProvider
          keyField="id"
          data={competitions?.sort((a, b) => a.level - b.level) || []}
          columns={listColumns()}
          bootstrap4
        >
          {toolkitProps => (
            <Card>
              <CardBody>
                <Col className="d-flex justify-content-between mb-3">
                  <h1>Competitions Management</h1>
                  <div className="text-sm-right">
                    <Link
                      className="btn-rounded waves-effect waves-light mb-2  btn btn-success"
                      to={`/add/${id}/competition`}
                    >
                      <i className="mdi mdi-plus mr-1" />
                      Add competition
                    </Link>
                  </div>
                </Col>
                <Row>
                  <Col xl="12">
                    {competitions?.length ? (
                      <div className="table-responsive">
                        <BootstrapTable
                          responsive
                          remote
                          bordered={false}
                          striped={false}
                          classes={
                            "table table align-middle table-nowrap table-hover"
                          }
                          rowClasses={rowClasses}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                        />
                      </div>
                    ) : (
                      <NoCompetitions />
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}
        </ToolkitProvider>
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {modalType === "statusModal"
            ? activeEvent?.competitions?.filter(
                comp => comp?._id === competitionId
              )[0]?.status === "DISABLED"
              ? "ENABLE COMPETITION"
              : "DISABLE COMPETITION"
            : "Remove competition confirmation"}
        </ModalHeader>
        <ModalBody>
          {modalType === "statusModal" ? (
            activeEvent?.competitions?.filter(
              comp => comp?._id === competitionId
            )[0]?.status === "DISABLED" ? (
              <p className="mb-0">
                Are you sure you want to{" "}
                <span className="font-weight-bold">ENABLE</span> this
                competition? (ACTIVE competitions are visible on the Website)
              </p>
            ) : (
              <p className="mb-0">
                Are you sure you want to{" "}
                <span className="font-weight-bold">DISABLE</span> this
                competition? (INACTIVE competitions are not visible on the
                Website)
              </p>
            )
          ) : (
            "All information entered will be lost if you delete the competition. Are you sure?"
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={async () => {
              {
                modalType === "statusModal"
                  ? await enableCompetition(
                      id,
                      competitionId,
                      type,
                      socketClientId
                    )
                  : await deleteCompetition(id, competitionId, socketClientId)
              }
              toggle()
              await fetchEvents(id)
            }}
            color="primary"
          >
            Yes
          </Button>
          <Button color="secondary" onClick={toggle}>
            no
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const mapStatetoProps = ({ events: { events = [] } = {} }) => ({
  events,
})

export default connect(mapStatetoProps, { fetchEvents: getEventDetails })(
  Competitions
)
